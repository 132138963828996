import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/edwaldoalmeida.png'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="https://www.edwaldoalmeida.com" className="image avatar"><img src={avatar} alt="Edwaldo Almeida picture" /></a>
                    <h1><strong>I'm Edwaldo Almeida, </strong><br />
                    Software Developer with experience in Networking & Security, <br />
                    Project Management and Business. </h1>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
